




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../../entity/EntityCrud.vue";
import monthlyCalendarAdminModel from "@/api/monthly_calendar.admin";

@Component({ components: { EntityCrud } })
export default class MonthlyCalendar extends Vue {
	model: any = monthlyCalendarAdminModel;

	title = "Calendário";
	tableColumns = [
		{
			key: "month",
			valueTransform: (month: string) => {
				const monthName = new Date(2022, Number(month) - 1).toLocaleDateString("pt-BR", { month: "long" });

				return monthName.charAt(0).toUpperCase() + monthName.slice(1);
			},
		},
		"year",
	];
	filter = {};

	formColumns = ["month", "year", "excelFileUrl", "calendarFileUrl"];
	defaultEntity = {};
}
